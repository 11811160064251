<template>
  <div
    v-if="applicationCtrl === 'show'"
    class="unselect"
    style="width:100%;height:100%;overflow:hidden"
  >
    <v-app-bar app elevation="1" light clipped-right class="white">
      <img height="30" :src="`${baseUrl}/upload/login/logo.png`" />
      <v-spacer style="border-left:1px solid #eee;" class="ml-3 pl-4"
        >应用面板</v-spacer
      >
      <div class="text-right">
        <v-menu :nudge-width="140" :nudge-bottom="28">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" color="#444">
              <v-app-bar-nav-icon class="menu"></v-app-bar-nav-icon>
            </v-btn>
          </template>
          <v-sheet class="overflow-hidden">
            <v-list dense expand nav>
              <v-list-item to="/user/home">
                <v-list-item-title class="d-flex align-center justify-center">
                  <v-icon class="mr-2">mdi-account</v-icon>
                  个人中心
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title class="d-flex align-center justify-center">
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  退出登录
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </div>
    </v-app-bar>

    <div
      v-if="applications.length > 0"
      class="white lighten-2 mt-16 px-6 d-flex flex-column flex-lg-grow-0 flex-md-grow-0"
      style="width: 100%;max-width:400px;margin:0 auto"
    >
      <div
        class="d-inline-flex flex-row justify-space-between"
        style="border-bottom: 1px dotted grey;line-height:48px"
      >
        <div class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6">
          应用列表
        </div>
      </div>
      <div class="white">
        <div
          class="text-caption d-flex flex-row justify-space-between pl-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px;"
          v-for="application in applications"
          :key="application.name"
          @click="toCurUrl(application)"
        >
          <div class="black--text">{{ application.name }}</div>
          <div>
            <a class="black--text" style="text-decoration:none;">
              点击前往<v-btn icon><v-icon>mdi-chevron-right</v-icon></v-btn>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="expApplications.length > 0">
      <div
        class="white lighten-2 my-2 px-6 d-flex flex-column flex-lg-grow-0 flex-md-grow-0"
        style="width: 100%;max-width:400px;margin:0 auto"
        v-for="(item, index) in expApplications"
        :key="`${item.name}_${index}`"
      >
        <div
          class="d-inline-flex flex-row justify-space-between"
          style="border-bottom: 1px dotted grey;line-height:48px"
        >
          <div class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6">
            {{ item.name || "门户站点" }}
          </div>
        </div>
        <div
          class="white"
          v-for="(itm, idx) in item.links"
          :key="`${item.name}_${idx}`"
        >
          <div
            class="text-caption d-flex flex-row justify-space-between pl-2"
            style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px;"
            @click="toCurExpUrl(expId, itm)"
          >
            <div class="black--text">{{ itm.name }}</div>
            <div>
              <a class="black--text" style="text-decoration:none;">
                点击前往<v-btn icon><v-icon>mdi-chevron-right</v-icon></v-btn>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import { api_request } from "@/util/network";
import { externalBrowser } from "@/util/util";

export default {
  name: "UserApplication",
  data() {
    return {
      applications: [],
      expApplications: [],
      expId: null,
      expName: null,
      expType: null,
      baseUrl: null,
      applicationCtrl: null,
      autorunApplication: ""
    };
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    let finishData = JSON.parse(localStorage.getItem("finishData"));
    if (finishData && finishData.identity_token) {
      this.$router.push("/user/home");
    } else {
      if (this.$route.params.id) {
        this.autoRun(this.$route.params.id);
      } else {
        this.applicationCtrl = "show";
      }
    }
    if (!this.$store.state.identity.claims.role) {
      this.fetchStyle();
      this.fetchApplications();
      if (this.$route.params.id) {
        this.autoRun(this.$route.params.id);
      } else {
        this.applicationCtrl = "show";
      }
    } else {
      this.$router.push("/user/home");
    }
  },
  methods: {
    fetchStyle() {
      return this.$http
        .get(`api/settings/launchpad`)
        .delegateTo(api_request)
        .then(res => {
          let { autorunApplication } = res;
          this.autorunApplication = autorunApplication;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    toCurUrl(item) {
      this.$http
        .post(`api/application/${item.id}/initiate`)
        .delegateTo(api_request)
        .then(data => {
          if (data.type === "REDIRECT_URL") {
            location.href = data.action;
          } else {
            let { action, method, inputs } = data.action;
            let form = document.createElement("form");
            form.action = action;
            form.method = method;

            for (let i = 0; i < inputs.length; i++) {
              let input = document.createElement("input");
              input.name = Object.keys(inputs[i])[0];
              input.value = Object.values(inputs[i])[0];
              form.appendChild(input);
            }
            document.body.appendChild(form).submit();
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    toCurExpUrl(id, item) {
      this.$http
        .get(item.url, {
          headers: { "X-Webexp-Connect": "IAM" },
          timeout: 2000,
          credentials: false
        })
        .then(() => {
          this.doInitiate(id, item, false);
        })
        .catch(() => {
          this.doInitiate(id, item, true);
        });
    },
    doInitiate(id, item, unreachableVal) {
      this.$http
        .post(`api/application/${id}/initiate`, {
          link_id: item.link_id,
          link_name: item.name,
          site_id: item.site_id,
          site_name: item.name,
          webexp_unreachable: unreachableVal
        })
        .delegateTo(api_request)
        .then(data => {
          location.href = data.action;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          this.$snackbar.showMessage({
            content: `登出成功`,
            isError: false
          });
          setTimeout(async () => {
            let last_account = localStorage.getItem("last-account");
            sessionStorage.setItem("logoutStatus", true);
            localStorage.clear();
            localStorage.setItem("last-account", last_account);
            await this.$store.commit("logout");

            if (externalBrowser()) {
              window.WeixinJSBridge.invoke("closeWindow");
            } else {
              this.$router.push("/login");
            }
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchApplications() {
      this.$http
        .get(`api/applications`)
        .delegateTo(api_request)
        .then(data => {
          if (data.length > 0) {
            this.applications = data.filter(item => item.type !== "WebEXP");
            let expData = data.filter(item => item.type === "WebEXP");
            if (
              expData &&
              expData[0] &&
              expData[0].attributes &&
              expData[0].attributes.length > 0
            ) {
              let { id, name, type, attributes } = expData[0];
              this.expId = id;
              this.expName = name;
              this.expType = type;
              this.expApplications = attributes;
            }
          }
        })
        .catch(({ code, message }) => {
          throw `获取应用列表数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    autoRun(id) {
      return this.$http
        .post(`api/application/${id}/initiate`)
        .delegateTo(api_request)
        .then(data => {
          if (data.type === "REDIRECT_URL") {
            window.location.href = data.action;
          } else {
            let { action, method, inputs } = data.action;
            let form = document.createElement("form");
            form.action = action;
            form.method = method;

            for (let i = 0; i < inputs.length; i++) {
              let input = document.createElement("input");
              input.name = Object.keys(inputs[i])[0];
              input.value = Object.values(inputs[i])[0];
              form.appendChild(input);
            }
            document.body.appendChild(form).submit();
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Snackbar
  }
};
</script>

<style lang="less" scoped>
/deep/.v-btn:not(.v-btn--round).v-size--default {
  height: 0;
  min-width: 0;
  padding: 0;
}
/deep/ .v-toolbar__content {
  padding: 4px 0 4px 12px;
}

.menu {
  .v-icon {
    font-size: 30px;
  }
}

.v-menu__content--fixed {
  left: auto !important;
  right: 0 !important;
}
</style>
