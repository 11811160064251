<template>
  <v-app>
    <MobileApplication
      v-if="mobileDevice === 'mobileDevice'"
    ></MobileApplication>
    <PcApplication v-if="mobileDevice === 'pcDevice'"></PcApplication>
  </v-app>
</template>

<script>
import { isMobile } from "@/util/util";
import PcApplication from "./component/PcApplication";
import MobileApplication from "./component/MobileApplication";

export default {
  name: "login",
  data() {
    return {
      mobileDevice: null
    };
  },
  created() {
    if (isMobile()) {
      this.mobileDevice = "mobileDevice";
    } else {
      this.mobileDevice = "pcDevice";
    }
  },
  components: {
    PcApplication,
    MobileApplication
  }
};
</script>
