<template>
  <div
    v-if="showApplication === 'showApplication'"
    class="unselect"
    style="width:100%;height:100%;background:#F7F9FC;min-width:630px;"
  >
    <v-app-bar
      app
      elevation="1"
      light
      clipped-right
      class="pr-3"
      :style="{ backgroundColor: headerBgColor }"
    >
      <img height="40" :src="`${baseUrl}/upload/login/logo.png`" />
      <v-spacer
        v-if="headerTitle"
        style="border-left:1px solid #eee;min-width:100px"
        class="text-h6 ml-4 pl-4"
        >{{ headerTitle }}</v-spacer
      >
      <v-spacer v-if="!headerTitle" class="ml-4 pl-4"></v-spacer>
      <div class="text-center d-flex align-center">
        <v-text-field
          class="mr-4"
          v-model.trim="searchName"
          @compositionstart="flag = false"
          @compositionend="flag = true"
          @input="searchCurName(searchName)"
          @keyup="searchCurName(searchName)"
          @keyup.enter="searchCurName(searchName)"
          @click:append="searchCurName(searchName)"
          append-icon="mdi-magnify"
          placeholder="搜索站点或直接输入地址"
          dense
          hide-details
        ></v-text-field>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" color="#444">
              {{ fullname || login_name }}
              <v-icon class="ml-1">mdi-account</v-icon>
            </v-btn>
          </template>
          <v-sheet class="overflow-hidden">
            <v-list dense expand nav>
              <v-list-item to="/user/home">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-account</v-icon>
                  个人中心
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="#" @click="logout()">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  登出
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- 大小图标切换按钮 -->
    <div class="iconbtn" style="position:fixed;top:100px;right:24px;z-index:0">
      <v-btn @click="iconTab">
        <v-icon v-if="iconCtrl" style="font-size:36px" color="#ccc"
          >mdi-apps</v-icon
        >
        <v-icon v-if="!iconCtrl" style="font-size:36px" color="#ccc"
          >mdi-format-list-bulleted-square</v-icon
        >
      </v-btn>
    </div>

    <!-- 导航栏 -->
    <v-card
      class="mx-auto navigations"
      min-width="140"
      style="position:fixed;top:160px;right:24px;z-index:0;background:none;border-right:5px solid #ccc;"
      v-if="navigations.length > 1 && !searchNavigations"
      elevation="0"
    >
      <v-list flat style="background:none;">
        <v-list-item-group color="primary">
          <v-list-item v-for="item in navigations" :key="item.id">
            <v-list-item-content @click="anchor(item.id)">
              <v-list-item-title
                class="text-right nav-item"
                style="color:#aaa;font-size:18px;"
              >
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <v-card
      class="mx-auto navigations"
      min-width="140"
      style="position:fixed;top:160px;right:24px;z-index:0;background:none;border-right:5px solid #ccc;"
      v-if="searchNavigations"
      elevation="0"
    >
      <v-list flat style="background:none;">
        <v-list-item-group color="primary">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="text-right"
                style="color:#aaa;font-size:18px;"
              >
                搜索结果
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <!-- 主页面内容区 -->
    <div class="d-flex overflow-hidden">
      <div
        class="my-4 inner-wrapper"
        style="margin:0 auto"
        v-if="applications.length > 0"
      >
        <div class="ml-4 text-h5" style="padding-top:70px" id="section1">
          应用列表
        </div>
        <div v-for="application in applications" :key="application.index">
          <div
            :style="{ backgroundColor: cellBgColor }"
            :class="[
              iconCtrl ? 'application-small-item ma-3' : 'application-item ma-3'
            ]"
            @click="toCurUrl(application)"
          >
            <div
              :class="[
                iconCtrl
                  ? 'd-flex align-center'
                  : 'd-flex flex-column align-center text-center pt-10'
              ]"
            >
              <div
                :class="[
                  iconCtrl
                    ? 'smallimg d-flex justify-center align-center mx-4'
                    : 'bigimg d-flex justify-center align-center mx-2'
                ]"
              >
                <img
                  style="width:100%;"
                  :src="
                    require(`@/assets/sources/${
                      application.type === 'WebVPN'
                        ? 'WEBVPN'
                        : application.type
                    }.png`)
                  "
                />
              </div>
              <div>
                <a
                  style="display:block;font-size:18px;width:144px;height:24px;overflow:hidden;word-break:break-all;"
                  :class="[
                    iconCtrl
                      ? 'text-decoration-none black--text'
                      : 'mt-6 text-decoration-none black--text'
                  ]"
                >
                  {{ application.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- EXP主页面内容区 -->
    <div v-if="expApplications.length > 0">
      <div
        class="d-flex overflow-hidden"
        v-for="(item, index) in expApplications"
        :key="`${item.name}_${index}`"
      >
        <div
          class="my-4 inner-wrapper"
          style="margin:0 auto"
          v-if="item.links.length > 0"
        >
          <div
            :id="`webexp${index}`"
            class="ml-4 text-h5"
            style="padding-top:66px"
          >
            <v-badge color="#ccc" v-if="!cellWebexpBadge" content="WebEXP">
              {{ item.name || "门户站点" }}
            </v-badge>
            <span color="#ccc" v-else>
              {{ item.name || "门户站点" }}
            </span>
          </div>
          <div v-for="(itm, idx) in item.links" :key="`${itm.name}_${idx}`">
            <div
              style="position:relative"
              :style="{ backgroundColor: cellBgColor }"
              :class="[
                iconCtrl
                  ? 'application-small-item ma-3'
                  : 'application-item ma-3'
              ]"
              @click="toCurExpUrl(expId, itm)"
            >
              <div
                class="d-flex justify-end"
                :style="{
                  position: 'absolute',
                  top: iconCtrl ? '10px' : '12px',
                  right: iconCtrl ? '8px' : '12px'
                }"
                v-if="itm.https_on"
              >
                <v-icon color="#B2AAB2">
                  mdi-lock
                </v-icon>
              </div>
              <div
                :class="[
                  iconCtrl
                    ? 'd-flex align-center justify-center px-2'
                    : 'd-flex flex-column justify-center align-center text-center pt-4 px-2'
                ]"
              >
                <span class="ma-4" style="font-size:46px;" v-if="!iconCtrl">{{
                  itm.name.substr(0, 1).toUpperCase()
                }}</span>

                <span
                  class="ma-4"
                  style="font-size:46px;"
                  v-if="!cellHideIcon && iconCtrl"
                  >{{ itm.name.substr(0, 1).toUpperCase() }}</span
                >
                <div
                  :style="cellHideIcon && iconCtrl ? 'margin-left:15px' : ''"
                >
                  <a
                    :style="
                      cellHideIcon && iconCtrl
                        ? 'display:block;font-size:20px;width:190px;height:26px;overflow:hidden;word-break:break-all;'
                        : 'display:block;font-size:18px;width:144px;height:24px;overflow:hidden;word-break:break-all;'
                    "
                    :class="[
                      iconCtrl
                        ? 'text-decoration-none black--text'
                        : 'text-decoration-none black--text'
                    ]"
                  >
                    {{ itm.name }}
                  </a>
                  <div
                    :style="
                      cellHideIcon && iconCtrl
                        ? 'font-size:14px;color:#B2AAB2;width:200px;height:20px;overflow:hidden;word-break:break-all;'
                        : 'font-size:14px;color:#B2AAB2;width:140px;height:20px;overflow:hidden;word-break:break-all;'
                    "
                    :class="[iconCtrl ? '' : 'mt-1 text-center']"
                  >
                    {{ itm.url }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      style="position:absolute;top:20%;left:50%;margin-left:-16px;z-index:999"
    ></v-progress-circular>

    <Snackbar />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import { api_request } from "@/util/network";

export default {
  name: "PcApplication",
  data() {
    return {
      applications: [],
      expApplications: [],
      expId: null,
      expName: null,
      expType: null,
      role: null,
      searchName: null,
      flag: true,
      navigations: [],
      searchNavigations: false,
      fullname: null,
      login_name: null,
      loading: false,
      iconCtrl: false,
      baseUrl: null,
      headerTitle: "",
      headerBgColor: "#fff",
      cellBgColor: "#fff",
      autorunApplication: "",
      cellDefaultMode: "tile",
      cellWebexpBadge: false,
      cellHideIcon: false,
      showApplication: ""
    };
  },
  async created() {
    await this.fetchStyle();
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    let iconStatus = JSON.parse(localStorage.getItem("iconStatus"));
    if (iconStatus != null || iconStatus == false) {
      this.iconCtrl = iconStatus;
    }

    let bindData = JSON.parse(localStorage.getItem("bindData"));
    let needBindWechat = localStorage.getItem("bindWechat");
    if ((bindData && bindData.identity_token) || needBindWechat) {
      this.$router.push("/user/home");
    } else {
      if (this.$route.params.id) {
        this.autoRun(this.$route.params.id);
      } else {
        this.showApplication = "showApplication";
      }
    }

    this.fullname = this.$store.state.identity.claims.fullname;
    this.login_name = this.$store.state.identity.claims.login_name;

    if (!this.$store.state.identity.claims.role) {
      await this.fetchApplications();
    } else {
      this.$router.push("/user/home");
    }
  },
  methods: {
    fetchLocalCellMode() {
      let cellMode = JSON.parse(localStorage.getItem("cellMode"));
      if (cellMode !== "tile" || cellMode !== "spuare") {
        return null;
      } else {
        return cellMode;
      }
    },
    fetchStyle() {
      return this.$http
        .get(`api/settings/launchpad`)
        .delegateTo(api_request)
        .then(res => {
          let {
            headerTitle,
            autorunApplication,
            cellDefaultMode,
            cellWebexpBadge,
            cellHideIcon,
            headerBgColor,
            cellBgColor
          } = res;
          this.headerTitle = headerTitle;
          this.autorunApplication = autorunApplication;
          this.iconCtrl =
            (this.fetchLocalCellMode() || cellDefaultMode) === "tile"
              ? true
              : false;
          localStorage.setItem("cellMode", this.iconCtrl);

          this.cellWebexpBadge = cellWebexpBadge ? cellWebexpBadge : false;
          this.cellHideIcon = cellHideIcon ? cellHideIcon : false;
          this.headerBgColor = headerBgColor ? headerBgColor : "#FFFFFF";
          this.cellBgColor = cellBgColor ? cellBgColor : "#FFFFFF";
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    iconTab() {
      let iconStatus = JSON.parse(localStorage.getItem("iconStatus"));
      if (iconStatus != null || iconStatus == false) {
        this.iconCtrl = !iconStatus;
        localStorage.setItem("iconStatus", this.iconCtrl);
      } else {
        this.iconCtrl = !this.iconCtrl;
        localStorage.setItem("iconStatus", this.iconCtrl);
      }
    },
    anchor(id) {
      let curElement = document.getElementById(id);
      window.scroll(0, curElement.offsetTop - 50);
    },
    searchCurName(name) {
      const searchName = name.toLowerCase();
      if (searchName && this.flag) {
        this.applications = this.applications.filter(item =>
          item.name.toLowerCase().includes(searchName)
        );
        this.expApplications = this.expApplications.map(item => {
          item.links = item.links.filter(itm =>
            itm.name.toLowerCase().includes(searchName)
          );
          return item;
        });
        this.searchNavigations = true;
      } else {
        this.searchNavigations = false;
        this.applications = JSON.parse(localStorage.getItem("applications"));
        this.navigations = [];
        if (this.applications.length > 0) {
          this.navigations.push({ id: "section1", name: "应用列表" });
        }

        this.expApplications = JSON.parse(
          localStorage.getItem("expApplications")
        );

        if (this.expApplications.length > 0) {
          this.expApplications.forEach((item, index) => {
            if (item.links && item.links.length > 0) {
              this.navigations.push({
                id: `webexp${index}`,
                name: item.name ? item.name : "门户站点"
              });
            }
          });
        }
      }
    },
    toCurUrl(item) {
      this.$http
        .post(`api/application/${item.id}/initiate`)
        .delegateTo(api_request)
        .then(data => {
          if (data.type === "REDIRECT_URL") {
            window.open(data.action, "_blank");
          } else {
            let { action, method, inputs } = data.action;
            let form = document.createElement("form");
            form.action = action;
            form.method = method;

            for (let i = 0; i < inputs.length; i++) {
              let input = document.createElement("input");
              input.name = Object.keys(inputs[i])[0];
              input.value = Object.values(inputs[i])[0];
              form.appendChild(input);
            }
            document.body.appendChild(form).submit();
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    toCurExpUrl(id, item) {
      this.$http
        .get(item.url, {
          headers: { "X-Webexp-Connect": "IAM" },
          timeout: 2000,
          credentials: false
        })
        .then(() => {
          this.doInitiate(id, item, false);
        })
        .catch(() => {
          this.doInitiate(id, item, true);
        });
    },
    doInitiate(id, item, unreachableVal) {
      this.$http
        .post(`api/application/${id}/initiate`, {
          link_id: item.link_id,
          link_name: item.name,
          site_id: item.site_id,
          site_name: item.name,
          webexp_unreachable: unreachableVal
        })
        .delegateTo(api_request)
        .then(data => {
          window.open(data.action, "_blank");
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          this.$snackbar.showMessage({
            content: `登出成功`,
            isError: false
          });
          setTimeout(async () => {
            sessionStorage.setItem("logoutStatus", true);
            let cellMode = localStorage.getItem("cellMode");
            let iconStatus = localStorage.getItem("iconStatus");
            let last_account = localStorage.getItem("last-account");
            localStorage.clear();
            localStorage.setItem("cellMode", cellMode);
            localStorage.setItem("iconStatus", iconStatus);
            localStorage.setItem("last-account", last_account);
            await this.$store.commit("logout");
            this.$router.push("/login");
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchApplications() {
      this.loading = true;
      this.$http
        .get(`api/applications`)
        .delegateTo(api_request)
        .then(data => {
          this.loading = false;
          if (data.length > 0) {
            this.navigations = [];
            this.applications = data.filter(item => item.type !== "WebEXP");
            let applications = localStorage.getItem("applications");
            if (!applications) {
              localStorage.setItem(
                "applications",
                JSON.stringify(this.applications)
              );
            }

            if (this.applications.length > 0) {
              this.navigations.push({ id: "section1", name: "应用列表" });
            }

            let expData = data.filter(item => item.type === "WebEXP");
            if (
              expData &&
              expData[0] &&
              expData[0].attributes &&
              expData[0].attributes.length > 0
            ) {
              let { id, name, type, attributes } = expData[0];
              this.expId = id;
              this.expName = name;
              this.expType = type;
              this.expApplications = attributes;

              let expApplications = localStorage.getItem("expApplications");
              if (!expApplications) {
                localStorage.setItem(
                  "expApplications",
                  JSON.stringify(attributes)
                );
              }

              this.expApplications.forEach((item, index) => {
                if (item.links && item.links.length > 0) {
                  this.navigations.push({
                    id: `webexp${index}`,
                    name: item.name ? item.name : "门户站点"
                  });
                }
              });
            }
          }
        })
        .catch(({ code, message }) => {
          this.loading = false;
          throw `获取应用列表数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    autoRun(id) {
      return this.$http
        .post(`api/application/${id}/initiate`)
        .delegateTo(api_request)
        .then(data => {
          if (data.type === "REDIRECT_URL") {
            window.location.href = data.action;
          } else {
            let { action, method, inputs } = data.action;
            let form = document.createElement("form");
            form.action = action;
            form.method = method;

            for (let i = 0; i < inputs.length; i++) {
              let input = document.createElement("input");
              input.name = Object.keys(inputs[i])[0];
              input.value = Object.values(inputs[i])[0];
              form.appendChild(input);
            }
            document.body.appendChild(form).submit();
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Snackbar
  }
};
</script>

<style lang="less" scoped>
.iconbtn {
  /deep/ .v-btn:not(.v-btn--round).v-size--default {
    height: 0;
    min-width: 0;
    padding: 0;
  }
}

.navigations {
  /deep/ .v-list-item {
    min-height: 26px !important;
  }
}

/deep/ .v-list-item__content {
  padding: 0 !important;
}

/deep/ .v-toolbar__content {
  padding: 4px 0 4px 12px;
}

.menu {
  .v-icon {
    font-size: 30px;
  }
}

/deep/ .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 4px 10px 2px;
}

.application-item {
  float: left;
  width: 180px;
  height: 180px;

  border-radius: 8px;
  box-shadow: 0 0 20px 0 #eceef4 !important;
  transition: all 0.2s;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #eaebee;
}

.application-small-item {
  float: left;
  width: 282px;
  height: 80px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 #eceef4 !important;
  transition: all 0.2s;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #eaebee;
}

/deep/ .v-list-item__title:hover {
  color: #000 !important;
}

.bigimg {
  width: 64px;
  height: 64px;
}
.smallimg {
  width: 48px;
  height: 48px;
}

.application-small-item:hover,
.application-item:hover {
  box-shadow: 0 0px 15px 0 #e0e0e0 !important;
}

// 一行6列
@media screen and (min-width: 1241px) {
  .inner-wrapper {
    width: 1224px;
  }
}

// 一行5列
@media screen and (min-width: 1037px) and (max-width: 1240px) {
  .inner-wrapper {
    width: 1020px;
  }
}

// 一行4列
@media screen and (min-width: 841px) and (max-width: 1036px) {
  .inner-wrapper {
    width: 816px;
  }
}

// 一行3列
@media screen and (max-width: 840px) {
  .inner-wrapper {
    width: 612px;
  }
}
</style>
